<template>
    <div class="orderDetail">
        <div class="top">
            <div style="margin-left: 30px; margin-top: 20px;">
                <span style="font-size: 14px; cursor: pointer;" @click="emits('closeDetail')">
                    <i class="iconfont icon-back" style="font-size: 18px;"></i>
                    返回
                </span>
            <div style="margin-left: 10px;">
                <p style="font-size: 14px;color: #b1c2d4;">订单状态</p>
                <p style="font-size: 24px; display: flex;align-items: center;margin-top: 10px;">
                    <span class="iconfont icon-clock" style="font-size: 24px"></span>
                    <span style="margin-left: 10px; ">{{orderDetail.workOrderStatus=='1'?'待接单':orderDetail.workOrderStatus=='3'?'已完成':orderDetail.workOrderStatus=='4'?'已完成':'已接单'}}</span>
                </p>
            </div>
            </div>
            <div style="margin-top:40px" v-show="orderDetail.oddNumber">
                <p style="font-size: 14px;color: #b1c2d4;">物流状态</p>
                <span>
                        快递公司：{{orderDetail.corporation==='SF'?'顺丰':'跨越'}}
                        快递单号：{{orderDetail.oddNumber}}
                </span>
            </div>
            <img style="height:90px;margin-right: 20px;" src="../assets/image.png">
        </div>
      <div style="display: flex;height: 100%; padding: 15px;" >
        <div style="width: 74%;margin-right: 15px;">
            <NCard
                :segmented="{
                content: true,
                footer: 'soft',
                }"
            >
            <template #header>
                <span style="color: #96A62E;display: flex; align-items: center;" >
                    <img src="../assets/info.png" >
                    <b style="margin-left: 5px;" >维修单详情信息</b>
                </span>
            </template>
            <div v-if="orderDetail.solution" style="padding: 10px;border-bottom: #ccc 1px solid" >
                <p style="color:#3c6794;">解决方案</p>
                <p>{{orderDetail.solution}}</p>
            </div>
               <div style="padding: 10px" >
                <p style="color:#3c6794;">维修信息</p>
                <div class="repairInfo">
                    <div class="deviceInfo">
                        <span>
                            <em>待维修设备</em>
                            <div>
                                <b>{{orderDetail.equipmentType}}</b>
                                <p style="margin: 0px; color: #96A62E;font-size: 14px;" >序列号：{{orderDetail.equipmentSerialNumber  }}</p>
                            </div>
                        </span>
                        <span v-show="orderDetail.workOrderStatus!='1'">
                            <em>维修工程师</em>
                            <img style="width: 40px; height: 40px;margin-left: 10px;"  src="../assets/avatar.png">
                            <div>
                                <div>
                                    <b>{{orderDetail.engineerName}}</b>
                                    <p style="margin: 0;">{{ orderDetail.phone }}</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <em>故障类型</em>
                            <b style="margin-left: 10px;" >{{ orderDetail.repairType }}</b>
                        </span>
                        <span style="flex-direction: column;">
                            <em>故障描述</em>
                            <b>{{orderDetail.faultInformation}}</b>
                            <div style="display: flex">
                                <span style="position:relative" >
                                    <NImage v-for="img in pictureList" :key="img.url" style="height: 80px; width: 80px" :src="img.url"  :render-toolbar="renderToolbar" @click="previewPic(img)"></NImage>
                                </span>
                                <video v-for="video in videoList" :key="video" style="height: 80px; width: 80px" :src="video" controls></video>
                            </div>
                        </span>
                    </div>
                </div>
               </div>
            </NCard>
        </div>
        <div style="flex:1">
            <NCard
                :segmented="{
                content: true,
                footer: 'soft',
                }"
            >
            <template #header>
                <span style="color: #96A62E;display: flex; align-items: center;" >
                    <img src="../assets/info.png" >
                    <b style="margin-left: 5px;" >维修单详情信息</b>
                </span>
            </template>
               <div>
                <div class="userInfo">
                    <b>{{orderDetail.userName  }}  {{ orderDetail.phone }}</b>
                    <p>{{orderDetail.email}}</p>
                </div>
                <div class="userInfo" style="margin: 20px 0px" v-show="orderDetail.sceneEnginnerId">
                    <p style="font-weight: 700;margin-bottom: 10px;">现场安排</p>
                    <div>
                        <div><span style="font-weight: 700">组别</span>  {{group}}</div>
                        <div><span style="font-weight: 700">维修人员</span>  {{orderDetail.sceneEnginnerId  }}</div>
                        <div><span style="font-weight: 700">联系方式</span>  {{orderDetail.sceneUserEmail  }}</div>
                        <div><span style="font-weight: 700">预计到达时间</span>  {{orderDetail.fieldTime  }}</div>
                    </div>
                </div>
                <div class="orderInfo">
                    <p>公司名称</p>
                    <b>{{ orderDetail.companyName }}</b>
                    <p>公司地址</p>
                    <b>{{orderDetail.provinceName}} {{ orderDetail.cityName }} {{orderDetail.districtName}} {{orderDetail.fullAddress}}</b>
                    <p>订单编号</p>
                    <b>{{ orderDetail.code }}</b>
                    <p>下单时间</p>
                    <b>{{orderDetail.createDate}}</b>
                </div>
               </div>
            <template #footer>
                <NButton @click="handleRepairWorkOrder" v-if="orderDetail.workOrderStatus=='1'" color="#bbd03a" style="width: 100%;">接单</NButton>
                <div class="buttonGroup" v-if="orderDetail.workOrderStatus=='2'||orderDetail.workOrderStatus=='5'">
                    <span @click="handleTurnover">
                        <i class="iconfont icon-arrow"></i>
                        转单
                    </span>
                    <span @click="handleOpenExpress">
                        <i class="iconfont icon-kuaidi"></i>
                        填写快递信息
                    </span>
                    <span @click="handlefeedBack">
                        <i class="iconfont icon-yijianfankui"></i>
                        解决方案
                    </span>
                    <span @click="handleApplyScene">
                        <i class="iconfont icon-yijianfankui"></i>
                        现场安排
                    </span>
                    <span @click="closeModal=true">
                        <i class="iconfont icon-bx-task-x"></i>
                        关闭工单
                    </span>
                </div>
            </template>
            </NCard>
        </div>
      </div>
      <!-- 填写快递信息 -->
      <NModal
        v-model:show="expressModal"
        class="custom-card"
        preset="card"
        style="width: 20%;"
        :bordered="false"
        :segmented="{
        content: 'soft',
        footer: 'soft'
      }"
    >
        <template #header>
            <span style="color: #bbd03a;">
                填写快递信息
            </span>
        </template>
        <NForm ref="expressRef" :model="expressForm" :rules="expressRules">
            <NFormItem path="corporation" label="快递公司">
               <NSelect 
               v-model:value="expressForm.corporation"
               placeholder="请选择"
               :options="expressCompanyList"/>
            </NFormItem>
            <NFormItem path="oddNumber" label="快递单号">
                <NInput
                    placeholder="请输入"
                    v-model:value="expressForm.oddNumber"
                />
            </NFormItem>
        </NForm>
        <template #footer>
        <NButton style="width: 100%;" color="#bbd03a" @click="handleExpress">保存</NButton>
        </template>
    </NModal>
    <!-- 转单 -->
    <NModal
        v-model:show="transformModal"
        preset="card"
        style="width: 20%;"
        :bordered="false"
        :segmented="{
        content: 'soft',
        footer: 'soft'
      }"
    >
        <template #header>
            <span style="color: #bbd03a;">
                转单
            </span>
        </template>
            <NInput placeholder="搜索工程师" v-model:value="userName" @keydown="handleKeydown" clearable>
                <template #prefix>
                    <i class="iconfont icon-search" style="color: #bbd03a;"></i>
                </template>
            </NInput>
            <NList>
                <NListItem v-for="engineer in engineerList" :key="engineer.id" @click="selectEngineer(engineer)">
                    <template #prefix>
                        <img style="width: 40px; height: 40px;margin-left: 10px;"  src="../assets/avatar.png">
                    </template>
                    <div style="display:flex;justify-content: space-between">
                        <div style="height: 40px;">
                            <b>{{ engineer.name }}</b>
                            <p>{{engineer.email}}</p>
                        </div>
                        <i v-show="engineer.id===engineerId" style="font-size: 20px;" class="iconfont icon-check"></i>
                    </div>
                </NListItem>
            </NList>
        <template #footer>
        <NButton style="width: 100%;" color="#bbd03a" @click="confirmSelect">确认选择</NButton>
        </template>
    </NModal>
    <!-- 现场安排 -->
    <NModal
        v-model:show="sceneModal"
        class="custom-card"
        preset="card"
        style="width: 20%;"
        :bordered="false"
        :segmented="{
        content: 'soft',
        footer: 'soft'
      }"
    >
        <template #header>
            <span style="color: #bbd03a;">
                现场安排
            </span>
        </template>
        <NForm ref="formRef" :model="sceneForm" :rules="rules">
            <NFormItem path="name" label="组别">
                <NSelect
                    placeholder="请选择"
                    v-model:value="groupType"
                    :options="groupList"
                    @updateValue="getSceneEngineerList"
                />
            </NFormItem>
            <NFormItem path="name" label="姓名">
                <NSelect
                    placeholder="请选择"
                    v-model:value="sceneForm.sceneEnginnerId"
                    :options="sceneEngineerList"
                    label-field="name"
                    value-field="id"
                    @updateValue="handleSelectSceneEngineer"
                />
            </NFormItem>
            <NFormItem path="mobile" label="联系方式">
                <NInput
                    placeholder="请输入"
                    v-model:value="sceneForm.sceneUserEmail"
                    :disabled="true"
                />
            </NFormItem>
            <NFormItem path="time" label="预计到达时间">
                <NDatePicker
                    placeholder="请选择"
                    v-model:formatted-value="sceneForm.fieldTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    style="width: 100%;"
                    clearable
                />
            </NFormItem>
        </NForm>
        <template #footer>
        <NButton style="width: 100%;" color="#bbd03a" @click="applySceneEngineer">保存</NButton>
        </template>
    </NModal>
    <!-- 解决方案 -->
    <NModal
        v-model:show="feedBackModal"
        class="custom-card"
        preset="card"
        style="width: 20%;"
        :bordered="false"
        :segmented="{
        content: 'soft',
        footer: 'soft'
      }"
    >
        <template #header>
            <span style="color: #bbd03a;">
                解决方案
            </span>
        </template>
        <NForm ref="formRef" :model="feedBackForm" :rules="rules">
            <p style="color:#3c6794;">解决方案</p>
            <NFormItem path="remark" label="解决方案详情">
                <NInput
                    type="textarea"
                    placeholder="请输入解决方案详情"
                    v-model:value="feedBackForm.solution"
                />
            </NFormItem>
            <!-- <p style="color:#3c6794;">现场维修信息</p>
            <NFormItem path="name" label="姓名">
                <NInput
                    placeholder="请输入"
                    v-model:value="feedBackForm.name"
                />
            </NFormItem> -->
        </NForm>
        <template #footer>
        <NButton style="width: 100%;" color="#bbd03a" @click="saveFeedback">保存</NButton>
        </template>
    </NModal>
    <!-- 关闭工单 -->
    <NModal
        v-model:show="closeModal"
        class="custom-card"
        preset="card"
        style="width: 20%;"
        :bordered="false"
        :segmented="{
        content: 'soft',
        footer: 'soft'
      }"
    >
        <template #header>
            <span style="color: #bbd03a;">
                操作确认
            </span>
        </template>
        确认关闭工单吗？
        <div style="display: flex;margin-top: 20px;justify-content: space-between">
            <NButton color="#bbd03a" style="width: 48%;height:40px" @click="handleClose">确认</NButton>
            <NButton style="width: 48%;height:40px" @click="closeModal=false" >取消</NButton>
        </div>
    </NModal>
    </div>
</template>

<script setup>
import { NCard, NButton, NModal, NForm, NFormItem, NSelect, NInput, NList, NListItem, NDatePicker ,useDialog,useMessage,NImage} from 'naive-ui'
import {getOrderDetail,repairWorkOrder,getEngineerList,trunoverOrder,writeExpress,feedBack,getSceneEngineer,getSceneEngineerType,closeOrder,parseUrl} from '../service/api.js'
import {ref,reactive,defineEmits,onMounted,defineProps,h} from 'vue' 
const expressModal = ref(false)
const transformModal = ref(false)
const sceneModal = ref(false)
const feedBackModal = ref(false)
const closeModal = ref(false)
const engineerList = ref([])
const groupList = ref([])
const sceneEngineerList = ref([])
const engineerId = ref()
const engineerName = ref()
const userName = ref(null)
const dialog = useDialog()
const message = useMessage()
const expressRef = ref()
const pictureList = ref([])
const videoList = ref([])
const imgUrl = ref()
const emits = defineEmits(['closeDetail'])
const props = defineProps({
    orderId:{
        type:Number,
        required:true
    }
})
const orderDetail = ref({})
const groupType = ref()
const group = ref()
const expressForm = reactive({
    corporation:null,
    corporationName:null,
    oddNumber:''
})
const sceneForm = reactive({
    sceneEnginnerId: null,
    sceneUserEmail: '',
    fieldTime: null,
    workOrderId: props.orderId,
    type:2
})
const feedBackForm=reactive({
    solution:'',
    type:1
})
const expressCompanyList = ref([
    {value: 'SF', label: '顺丰'},
    {value: 'KY', label: '跨越'}
])
const expressRules = {
    corporation: {
        required: true,
            message: '请选择快递公司',
            trigger: 'blur'
        },
        oddNumber: {
        required: true,
            message: '请输入快递单号',
            trigger: 'blur'
        }
}
const previewPic = (img) => {
    imgUrl.value=img
}
const downloadPic = () => {
    let link = document.createElement('a')
    link.style.display = 'none'
    link.href = imgUrl.value.url
    link.setAttribute('download', imgUrl.value.type)
    document.body.appendChild(link)
    link.click()
}
const renderToolbar = ({ nodes }) => {
      return [
        nodes.prev,
        nodes.next,
        nodes.zoomOut,
        nodes.zoomIn,
        h(
          NButton,
          {
            circle: true,
            type: 'primary',
            style: { marginLeft: '12px' },
            onClick: ()=>downloadPic()
          },
          {
              default: () => '下载'
          }
        ),
        nodes.close
      ]
    }
// 获取订单详情
const getDetailData = async() => {
    const res = await getOrderDetail(props.orderId)
    orderDetail.value = res.data
    const workOrderPicturesList = orderDetail.value.repairWorkOrderPictures
    if (workOrderPicturesList) {
        pictureList.value = []
        videoList.value=[]
        workOrderPicturesList.map(async picture => {
        const res = await parseUrl(picture.url)
            let url = URL.createObjectURL(res.data)
        const type=res.data.type
        const isImage = res.data.type.slice(0, 5)
            if (isImage === 'image') {
                pictureList.value.push({
                    url,
                    type:type.slice(6,type.length)
            })
        } else {
            videoList.value.push(url)
        }
    })
    }
    
    const repairResultFeedback1 = orderDetail.value.repairResultFeedback?.find(feedBack => {
        return feedBack.type===1
    }) || null
    const repairResultFeedback2 = orderDetail.value.repairResultFeedback?.find(feedBack => {
        return feedBack.type===2
    })||null
    if(repairResultFeedback1){
        orderDetail.value.solution = repairResultFeedback1.solution
    }
    if(repairResultFeedback2){
        group.value=repairResultFeedback2.groupType
        groupType.value=repairResultFeedback2.groupType
        orderDetail.value.sceneEnginnerId=repairResultFeedback2.sceneUserName
        orderDetail.value.sceneUserEmail=repairResultFeedback2.sceneUserEmail
        orderDetail.value.fieldTime = repairResultFeedback2.fieldTime
    }
    getSceneEngineerList()
}
// 获取工程师list
const getEngineer=async()=>{
    const res = await getEngineerList(
        {
            equipmentType:orderDetail.value.equipmentType,
            userName:userName.value
        }
    )
    engineerList.value= [ ...res.data.enjoyEngineers.map(e => ({...e, heart: true})),
          ...res.data.otherEngineers]
}
// 转单
const handleTurnover = ()=>{
    getEngineer()
    transformModal.value=true
}
const handlefeedBack = () => {
    feedBackModal.value = true
    const repairResultFeedback = orderDetail.value.repairResultFeedback?.find(feedBack => {
        return feedBack.type===1
    })||null
    if(repairResultFeedback){
        feedBackForm.solution=repairResultFeedback.solution
    }
}
// 确认转单工程师
const confirmSelect = async () => {
    if (engineerId.value) {
        if (props.orderId) {
          dialog.warning({
            title: '提示',
            content: `确认将该维修单转给工程师${engineerName.value}？`,
            positiveText: '确定',
            negativeText: '取消',
            onPositiveClick:async() => {
               const res= await trunoverOrder(props.orderId, engineerId.value);
                if (res.code === 0) {
                    message.success('转单成功');
                    emits('closeDetail')
               }
            }
          });
        } 
      } else {
        message.error('请选择工程师');
      }
}
// 选择工程师
const selectEngineer = (engineer)=>{
    engineerId.value=engineer.id
    engineerName.value=engineer.name
}
const handleKeydown = (e)=>{
    if (e.key === 'Enter') {
        getEngineer()
    }
}
// 接单
const handleRepairWorkOrder = async() => {
   const res= await repairWorkOrder(props.orderId)
    if(res.code===0){
        await getDetailData()
    }
}
// 填写快递信息
const handleExpress = async(e) => {
    e.preventDefault()
     expressRef.value?.validate(async(errors) => {
         if (!errors) {
            expressForm.corporationName=expressForm.corporation==='SF'?'顺丰':'跨越'
            const res=await writeExpress(props.orderId,expressForm)
            if(res.code===0){
                message.success('填写成功')
                expressModal.value = false
                await getDetailData()
            }
          }
          else {
            message.error('error')
          }
        })
}
const handleOpenExpress = () => {
    expressModal.value = true
    expressForm.corporation=orderDetail.value.corporation
    expressForm.oddNumber=orderDetail.value.oddNumber
}
const saveFeedback = async() => {
   const res= await feedBack({...feedBackForm,workOrderId:props.orderId})
    if (res.code === 0) {
        feedBackModal.value = false
        await getDetailData()
   }
}
// 选择现场工程师获取mobile
const handleSelectSceneEngineer = (value)=>{
    let sceneEngineer=sceneEngineerList.value.find(item=>{
       return item.id===value
    })
    sceneForm.sceneUserEmail=sceneEngineer?.mobile||null

}
// 获取现场工程师list
const getSceneEngineerList = async (value) => {
    if (value) {
        sceneForm.sceneEnginnerId=null
    }
    const res = await getSceneEngineer({type:value?value:group.value})
    sceneEngineerList.value=res.data
}
// 获取现场工程师组别
const getSceneEngineerTypeList = async() => {
    const res = await getSceneEngineerType()
    groupList.value=res.data.map(item=>{
        return {
            label: item,
            value: item
        }
    })
}
const applySceneEngineer = async () => {
   let sceneEngineer= sceneEngineerList.value.find(scene => {
       return scene.id===sceneForm.sceneEnginnerId
   })
    sceneForm.sceneUserName=sceneEngineer?.name||''
    const res = await feedBack(sceneForm)
    if (res.code === 0) {
        message.success('现场工程师安排成功')
        sceneModal.value = false
        getDetailData()
    }
}
const handleApplyScene = () => {
    const repairResultFeedback = orderDetail.value.repairResultFeedback?.find(feedBack => {
        return feedBack.type===2
    })||null
    sceneModal.value = true
        if (repairResultFeedback) {
        groupType.value=repairResultFeedback.groupType
            sceneForm.sceneEnginnerId = repairResultFeedback.senceEnginnerId ? repairResultFeedback.senceEnginnerId : repairResultFeedback.sceneUserName

        sceneForm.sceneUserEmail=repairResultFeedback.sceneUserEmail
        sceneForm.fieldTime=repairResultFeedback.fieldTime
    }
}
const handleClose = async() => {
    const res = await closeOrder(props.orderId)
    if(res.code===0){
        message.success('关闭成功')
        closeModal.value = false
        getDetailData()
    }else{
        message.error(res.message)
        closeModal.value=false
    }
}
onMounted(()=>{
    getSceneEngineerTypeList()
    getDetailData()
})
</script>

<style lang="scss" scoped>
p{
    margin: 0px;
}
.orderDetail{
    padding-top:70px;
    .top{
        background-color:#3c6794;
        height:150px;
        color:#fff;
        display:flex;
        justify-content:space-between;
        align-items: center;
    }
}
.repairInfo{
    width:100%;
    .deviceInfo{
        width:100%;
        display: flex;
        flex-wrap: wrap;
        span{
            width: 33%;
            display: flex;
            div{
                margin-left: 10px;
            }
            em{
                font-style: normal;
                color: #333;
            }
        }
    }
}
.userInfo{
    background-color: #f8fbeb;
    padding: 10px;
    p{
        margin-top: 5px
    }
}
.orderInfo{
    margin-top: 30px;
    p{
        color: #666;
        font-size: 12px;
        margin-top: 10px;
    }
    b{
        font-weight: normal;
    }
}
.buttonGroup{
        display: flex;
        span{
            color: #747929;
            width: 20%;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            i{
                font-size: 18px
            }
        }
    }
</style>