<template>
     <div class="cantainer">
       <div class="top" >
            <div class="logo">
                    <img src="../assets/logo.png" style="height: 36px; width: 36px; margin-left: 20px;">
                <span style="margin-left: 10px;">
                        <h3 style="margin: 0px;">
                            通快维修平台
                        </h3>
                        <span style="color: #0251BA;font-size: 14px;">工程师端</span>
                </span>
            </div>
            <span style="margin-right: 20px;">
                <i class="iconfont icon-avatar"  style=" font-size: 24px;"></i>
            </span>
       </div>
    <div class="orderList" v-if="!isDetail">
        <div class="topbg">
        <div class="left">
            <p style="margin: 15px 0; font-size: 18px;">通快 — 创新保障始于1923</p>
            <div class="topData">
                <span>
                    <b>476.0</b>
                    <span>百万欧元</span>
                    <em>研发支出</em>
                </span>
                <span>
                    <b>8.9%</b>
                    <span>研发（经费）占比</span>
                    <em>相较于营业额</em>
                </span>
                <span>
                    <b>2853.0</b>
                    <span>人员</span>
                    <em>致力于新产品研发</em>
                </span>
            </div>
            <p style="color: #333; margin: 20px 0;">作为高科技企业，我们在机床、激光技术以及电子领域提供生产解决方案</p>
        </div>
        <div class="right">
            <img src="../assets/flow.svg" style="width:75%">
            <img src="../assets/admin.svg" style="width: 15%; margin-right: 5%;">
        </div>
    </div>
    <div class="repair">
        <NCard>
           <p style="display: flex; justify-content: space-between;">
            <span>全部维修单</span>
            <NInput  placeholder="请输入：序列号、故障类型或工单编号" style="width: 18%;" @keydown="handleKeydown" v-model:value="searchKey" clearable>
                <template #prefix>
                    <i class="iconfont icon-search" style="color: #bbd03a;"></i>
                </template>
            </NInput>
           </p>
            <NTabs type="card" @before-leave="handleBeforeLeave" :value="tabName">
                <NTabPane v-for="item in paneList" :key="item.key" :name="item.name">
                    <table style="width: 100%;">
                        <thead>
                        <tr style="text-align: left;">
                            <th style="width: 30%; font-weight: normal;">名称</th>
                            <th style="width: 60%; font-weight: normal;">相关产品</th>
                            <th style="width: 10%; font-weight: normal;">状态</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr  @click="handleDetail(data)" v-for="data in listData" :key="data.id">
                            <td>
                               <div :class="data.workOrderStatus=='1'?'workStatus1 bg1': (data.workOrderStatus=='2'||data.workOrderStatus=='5'||data.workOrderStatus=='6')?'workStatus2 bg2':'workStatus3 bg3'" style="padding-left:10px ">
                                <p :style="{fontWeight:'700',color: data.repairType === '故障停机' ? 'red' : data.repairType === '一般报警' ? 'orange' : 'black'}">{{ data.repairType }}</p>
                                <p>{{ data.repairType }} | {{ data.createDate }}</p>
                               </div>
                            </td>
                            <td>
                               <div :class="data.workOrderStatus=='1'?' bg1': (data.workOrderStatus=='2'||data.workOrderStatus=='5'||data.workOrderStatus=='6')?' bg2':'bg3'" >
                                <p style="font-weight: 600;">
                                    <img src="../assets/vector.png">
                                    {{ data.equipmentType }}
                                </p>
                                <p>序列号 {{ data.equipmentSerialNumber }}</p>
                               </div>
                            </td>
                            <td>
                                <div :class="data.workOrderStatus=='1'?' bg1': (data.workOrderStatus=='2'||data.workOrderStatus=='5'||data.workOrderStatus=='6')?' bg2':'bg3'" style="line-height: 40px;text-align: right;padding-right: 10px;">
                                    <span style="margin-right: 10px;">
                                        {{(data.workOrderStatus=='5'||data.workOrderStatus=='6')?'已接单':data.workOrderStatus=='4'?'已完成': paneList.find(item=>item.key== data.workOrderStatus).name }}
                                    <NButton circle :type="data.workOrderStatus=='1'?'info': (data.workOrderStatus=='2'||data.workOrderStatus=='5'||data.workOrderStatus=='6')?'success':''" :color="(data.workOrderStatus=='3'||data.workOrderStatus=='4')?'#646464':''" size="tiny">
                                        <template #icon>
                                            <img src="../assets/arrow.png">
                                        </template>
                                    </NButton>
                                </span>
                                <p v-if="data.workOrderStatus=='6'" style="line-height: 20px; margin-top: -10px;color: #F57F17;">等待客户确认关闭中</p>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div @click="handleLoadingMore" style="display: flex;justify-content: center;cursor: pointer">
                        <p v-show="total>pageIndex*10">点击加载更多</p>
                    </div>
                <template #tab>
                    <div v-if="item.name==='待接单'">
                        <n-badge :value="number">
                            {{ item.name }}
                        </n-badge>
                    </div>
                    <div v-else>
                        {{ item.name }}
                    </div>
                </template>
                </NTabPane>
                <template #suffix>
                    <div>
                        <span text style="margin-right: 20px;cursor: pointer;" @click="handleOrderbyType">
                        报修类型
                        <i class="iconfont icon-cc-arrow-up" style="font-size: 12px;" :style="typeOrder==='ASC'?{color:'#bbd03a'}:''"></i>
                        <i class="iconfont icon-cc-arrow-down" style="font-size: 12px;" :style="typeOrder==='DESC'?{color:'#bbd03a'}:''"></i>
                    </span>
                    <span text style="cursor: pointer;" @click="handleOrderbyTime">
                        报修时间
                        <i class="iconfont icon-cc-arrow-up" style="font-size: 12px;" :style="timeOrder==='ASC'?{color:'#bbd03a'}:''"></i>
                        <i class="iconfont icon-cc-arrow-down" style="font-size: 12px;" :style="timeOrder==='DESC'?{color:'#bbd03a'}:''"></i>
                    </span>
                    </div>
                </template>
            </NTabs>
        </NCard>
    </div>
    </div>
    <div v-else style="height: 100%">
        <OrderDetail @closeDetail="closeDetail" :orderId="orderId"></OrderDetail>
    </div>
     </div>
</template>

<script setup>
import OrderDetail from './orderDetail'
import { NTabs, NTabPane, NCard, NInput, NButton,useNotification,NBadge,useMessage } from 'naive-ui'
import { ref, onMounted } from 'vue'
import { getRepairList, login,getNumber } from '../service/api.js'
// const socketUrl = 'wss://trumpf.tecpie.com'
const socketUrl = 'wss://trumpf.tecpie.com'
const tabName = ref('全部')
const paneList = [
    {
        name: '全部',
        key:'all'
    },
    {
        name: '待接单',
        key:'1'
    },
    {
        name: '已接单',
        key:'2'
    },
    {
        name: '已完成',
        key:'3'
    }
]
const orderId=ref()
const tabKey = ref(null)
const listData = ref([])
const isDetail = ref(false)
const searchKey = ref()
const pageIndex = ref(1)
const total = ref(0)
const number = ref(0)
const notification = useNotification()
const timeOrder = ref('DESC')
const typeOrder = ref('DESC')
const message = useMessage()
const orderByList = ref([])
 //页面创建的时候获取code（第一次获取不到，第二次进入页面时通过重定向的方式会携带过来）
 const code=ref()
 let Socket = ''
let setIntervalWesocketPush = null
//缓存ip
let ip = ''
/**
 * 建立websocket连接
 * @param {string} url ws地址
 */
const createSocket = url => {
    Socket && Socket.close()
    if (!Socket) {
        console.log('建立websocket连接')
        //建立连接的时候缓存服务端ip以便于其他方法调用
        ip = url;
        Socket = new WebSocket(url)
        //发送心跳
        Socket.onopen = onopenWS
        //接收消息
        Socket.onmessage = onmessageWS
        //连接失败重连
        Socket.onerror = onerrorWS
        //断开重连
        Socket.onclose = oncloseWS
    } else {
        console.log('websocket已连接')
    }
}

/**打开WS之后发送心跳 */
const onopenWS = () => {
    sendPing()
}

/**连接失败重连 */
const onerrorWS = () => {
    Socket.close()
    clearInterval(setIntervalWesocketPush)
    console.log('连接失败重连中')
    if (Socket.readyState !== 3) {
        Socket = null
        createSocket(ip)
    }
}

/**WS数据接收统一处理 */
const onmessageWS = e => {
    const resData = JSON.parse(e.data)
    if (resData.event === "place_an_order") {
        setTimeout(() => {
            getRepairDataList(tabKey.value)
            pendingNumber()
            notification.create({
                title: '有新的工单消息',
                duration: 3000,
        })
          }, 2000);
    }
}

/**
 * 发送数据但连接未建立时进行处理等待重发
 * @param {any} message 需要发送的数据
 */
// const connecting = message => {
//     setTimeout(() => {
//         if (Socket.readyState === 0) {
//             connecting(message)
//         } else {
//             Socket.send(JSON.stringify(message))
//         }
//     }, 1000)
// }

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
// const sendWSPush = message => {
//     if (Socket !== null && Socket.readyState === 3) {
//         Socket.close()
//         createSocket(ip)
//     } else if (Socket.readyState === 1) {
//         Socket.send(JSON.stringify(message))
//     } else if (Socket.readyState === 0) {
//         connecting(message)
//     }
// }

/**断开重连 */
const oncloseWS = () => {
    clearInterval(setIntervalWesocketPush)
    console.log('websocket已断开....正在尝试重连')
    if (Socket.readyState !== 2) {
        Socket = null
        createSocket(ip)
    }
}
/**发送心跳
 * @param {number} time 心跳间隔毫秒 默认5000
 * @param {string} ping 心跳名称 默认字符串ping
 */
const sendPing = (time = 5000, ping = 'ping') => {
    clearInterval(setIntervalWesocketPush)
    Socket.send(ping)
    setIntervalWesocketPush = setInterval(() => {
        Socket.send(ping)
    }, time)
}

const getUrlParam=(name) =>{
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = window.location.search.substr(1).match(reg)
    if (r != null) {
     return unescape(r[2])
    }
    return null
    }
const closeDetail = () => {
    isDetail.value = false
    getRepairDataList(tabKey.value)
    pendingNumber()
}
const handleBeforeLeave = async (val) => {
    tabName.value=val
    pageIndex.value = 1
    let workOrderStatus
    workOrderStatus =  paneList.find(item => {
       return item.name===val
    })
    if (workOrderStatus) {
        tabKey.value=workOrderStatus.key==='all'?null:workOrderStatus.key
        workOrderStatus.key==='all'? await getRepairDataList():await getRepairDataList(workOrderStatus.key)
    }
    return true
}
const getRepairDataList = async (workOrderStatus = null) => {
    // if (timeOrder.value) {
    //     orderByList.push({ column: "main.create_date", direction: timeOrder.value })
    // }
    // if (typeOrder.value) {
    //     orderByList.push({ column: "t.sort", direction: typeOrder.value})
    // }
    const {data} = await getRepairList({
        condition: {
            conditionInquire:searchKey.value,
            orderByList:orderByList.value,
            workOrderStatus
        },
        pageIndex: pageIndex.value,
        pagSize: 10
    })
    listData.value = data.data
    total.value = data.total
}
const handleLoadingMore = async () => {
    pageIndex.value += 1
    const {data} = await getRepairList({
        condition: {
            conditionInquire:searchKey.value,
            orderByList:orderByList.value,
            workOrderStatus:tabKey.value
        },
        pageIndex: pageIndex.value,
        pagSize: 10
    })
    listData.value = [...listData.value, ...data.data]
    total.value=data.total
}
const handleKeydown = (e)=>{
    if (e.key === 'Enter') {
        getRepairDataList(tabKey.value)
    }
}
const handleDetail = (data) => {
    orderId.value=data.id
    isDetail.value=true
}
const pendingNumber = async() => {
    const res = await getNumber()
    number.value=res.data
}
const handleOrderbyType = () => {
    try {
        typeOrder.value=typeOrder.value === 'DESC'?"ASC":"DESC"
        orderByList.value = [
            {column:"t.sort",direction:typeOrder.value},
            {column:"main.create_date",direction:timeOrder.value}
        ]
        pageIndex.value=1
        getRepairDataList(tabKey.value)
    } catch (error) {
        message.error(error)
    }
}
const handleOrderbyTime = () => {
    try {
        timeOrder.value=timeOrder.value === 'DESC'?"ASC":"DESC"
        orderByList.value = [
            { column: "main.create_date", direction: timeOrder.value },
            {column:"t.sort",direction:typeOrder.value}
        ]
        pageIndex.value=1
        getRepairDataList(tabKey.value)
    } catch (error) {
        message.error(error)
    }
}
onMounted(async() => {
    code.value = getUrlParam('code')
    if (code.value) {
        const res = await login({ code: code.value, loginType: "4" })
        if (res.code === 0) {
            localStorage.setItem('token', res.data.token)
            window.location.href = 'https://azuretest.trumpf.cn:18090/'
        }
    } else {
        const token = localStorage.getItem('token')
        createSocket(`${socketUrl}/ws/message?Authorization=Bearer%20${token}`)
        getRepairDataList()
        pendingNumber()
    } 
})
</script>

<style lang="scss" scoped>
p{
    margin: 0px;
}
.top{
    width: 100%;
        background-color: #fff;
        position: fixed;
        top:0;
       display: flex;
       justify-content: space-between;
       align-items: center;
        box-shadow:1px 1px 1px #ccc;
        .logo{
            height: 80px;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
.topbg{
    background-image: url(../assets/BG.png);
    background-repeat:no-repeat;
	background-size:100% 100%;
    height: 200px;
    display: flex;
    padding-top: 100px;
    padding-bottom: 40px;
    .left{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 50%;
        .topData{
            display: flex;
            align-items: center;
            width: 100%;
            span{
                width: 33%;
                display: flex;
                align-items: center;
                flex-direction: column;
                b{
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 28px;
                }
                span{
                    font-size: 12px;
                    font-weight: 600;
                    margin: 5px 0px
                }
                em{
                    font-style: normal;
                    font-size: 12px;
                    color: #333;
                }
            }
        }
    }
    .right{
        flex: 1;
        margin-left: 10px;
        border-left: 1px solid #000;
        display: flex;
        justify-content: space-evenly;
    }
}
.repair{
    table{
        border-collapse:collapse;
    }
   tbody{
        tr{
           td{
            padding: 8px 0;
            border-radius: 5px;
            div{
                height: 46px;
                padding: 12px 0;
            }
           }
        }
   }
}
.workStatus1{
    border-radius: 5px 0 0 5px;
    border-left: 5px solid #3b73be;
   
}
.workStatus2{
    border-radius: 5px 0 0 5px;
    border-left: 5px solid #339306; 
    
}
.workStatus3{
    border-radius: 5px 0 0 5px;
    border-left: 5px solid #969696; 
    
}
.bg1{
    background-color: #f5f9ff;
     span{
        color:#3b73be
    }
}
.bg2{
    background-color: #edffe7;
    span{
        color:#339306
    }
}
.bg3{
    background-color: #fafafa;
    span{
        color:#969696
    }
}
</style>