import axios from 'axios'
const token = `Bearer ${localStorage.getItem('token')}`
const service = axios.create({
  baseURL:'https://trumpf.tecpie.com/api/v1', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})
import {appid,agentid,redirect_uri} from '../config/config'
const getCode=()=> {
  const url = 'https://open.weixin.qq.com/connect/oauth2/authorize'
  const wx_url = url + '?appid=' + appid + '&redirect_uri=' + redirect_uri +'&response_type=code&scope=snsapi_privateinfo' + '&agentid=' + agentid+'#wechat_redirect'
  window.location.href = wx_url
}
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Authorization'] = token
    config.headers['Client'] = 1
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.data instanceof Blob) return response.data
    return {
      data: response?.data?.data,
      message:response?.data?.message,
      code:response.data.code
    }
  },
  error => {
    if (error.response?.status === 401) {
      window.$message?.error('登录过期，请重新登录')
      localStorage.removeItem('token')
      getCode()
    }
    if (error.response?.data.message) {
      window.$message?.error(error.response?.data.message)
    }
    return Promise.reject(error)
  }
)

export default service
