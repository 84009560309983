import request from './instance.js'
import download from './download.js'
export function getRepairList(data) {
    return request({
        url: 'repairWorkOrder/page',
        method: 'post',
        data
    })
}
export function getOrderDetail(id) {
    return request({
        url: `repairWorkOrder/${id}`,
    })
}
export function repairWorkOrder(id) {
    return request({
        url: `repairWorkOrder/orderTaking/${id}`,
        method: 'post',
    })
}
export function getEngineerList(data) {
    return request({
        url: 'repairUserEngineer/enjoy',
        method: 'post',
        data
    })
}
export function trunoverOrder(workOrderId,engineerId) {
    return request({
        url: `repairWorkOrder/${workOrderId}/reconnection/${engineerId}`,
        method:'post'
    })
}
export function writeExpress(workOrderId, data) {
    return request({
        url: `/repairWorkOrder/${workOrderId}/addExpress`,
        method: 'post',
        data
    })
}
export function feedBack(data) {
    return request({
        url: 'repairWorkOrder/resultFeedback',
        method: 'post',
        data
    })
}
export function getSceneEngineerType() {
    return request({
        url:'repairSceneEngineer/getTypes'
    })
}
export function getSceneEngineer(data) {
    return request({
        url: 'repairSceneEngineer/list',
        method: 'post',
        data
    })
}
export function closeOrder(workOrderId) {
    return request({
        url: `repairWorkOrder/${workOrderId}/closeWorkOrder`,
        method: 'post',
    })
}
export function parseUrl(url) {
    return download({
        url:`common/displayBlob?url=${url}`
    })
}
export function login(data) {
    return request({
        url: 'access/login',
        method: 'post',
        data
    })
}
export function getNumber() {
    return request({
        url:'repairWorkOrder/getNumber'
    })
}